<template>
  <div>
    <div v-if="allWppGroups.length > 0">
      <b-table
        :data="allWppGroups"
        :bordered="true"
        :striped="true"
        :narrowed="false"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
        paginated>

        <b-table-column :numeric="true" field="id" label="Ticket ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="ww_phone" label="Origem" v-slot="props">
          <PhoneTag :phone="props.row.ww_phone" :name="props.row.setting.name" :color="props.row.setting.color" :width="false" />
        </b-table-column>

        <b-table-column field="name" label="Nome" v-slot="props">
          {{ props.row.client_name }}
        </b-table-column>

        <b-table-column field="attendant" label="Responsável" v-slot="props">
          {{ props.row.attendant ? props.row.attendant.name : '' }}
        </b-table-column>

        <b-table-column field="update" label="" v-slot="props">
          <button
            class="button is-info is-outlined"
            expanded
            type="button"
            @click="updateGroup(props.row)"
          >
            Atualizar
          </button>
        </b-table-column>

      </b-table>
    </div>
  </div>
</template>

<script>
import PhoneTag from '../commons/PhoneTag.vue'

export default {
  name: 'WppGroupsList',
  props: {
    wppGroups: {
      type: Array,
      required: true
    }
  },
  components: {
    PhoneTag
  },
  computed: {
    allWppGroups: {
      get () {
        return this.wppGroups
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    updateGroup (ticket) {
      this.$emit('updateGroup', ticket)
    }
  }
}
</script>

<style lang="scss">

</style>
