<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <ModalUpdateWppGroup
        v-if="isComponentModalActive"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isComponentModalActive"
        :groupUpdating="groupUpdating"
        :attendants="getAttendants"
        :departments="allDepartments"
        v-on:updateGroup="handleUpdateGroup"
      />
      <WppGroupsList
        :wppGroups='allWppGroups'
        v-on:updateGroup='openUpdateGroup'
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import { mapActions, mapGetters } from 'vuex'
import WppGroupsList from '@/components/wppGroups/WppGroupsList'
import ModalUpdateWppGroup from '@/components/modals/ModalUpdateWppGroup'

export default {
  name: 'WppGroups',
  components: {
    TitleBar,
    WppGroupsList,
    ModalUpdateWppGroup
  },
  data () {
    return {
      isComponentModalActive: false,
      groupUpdating: null
    }
  },
  computed: {
    ...mapGetters(['allWppGroups', 'fetchingWppGroups', 'allAttendants', 'allDepartments']),
    titleStack () {
      return ['Chat', 'Grupos de whatsapp']
    },
    getAttendants () {
      return this.allAttendants.filter(att => att.isActive)
    }
  },
  methods: {
    ...mapActions([
      'fetchWppGroups',
      'updateWppGroup',
      'fetchWppGroupDetails',
      'fetchAttendants',
      'updateWppGroup',
      'fetchDepartments'
    ]),
    closeModal () {
      this.groupUpdating = null
      this.isComponentModalActive = false
    },
    async openUpdateGroup (ticket) {
      this.groupUpdating = await this.fetchWppGroupDetails(ticket.id)
      this.fetchAttendants()
      this.fetchDepartments(ticket.ww_phone)
      this.isComponentModalActive = !this.isComponentModalActive
    },
    handleUpdateGroup (group) {
      this.closeModal()
      this.updateWppGroup(group).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro',
          message:
            'Ocorreu um <b>erro</b> ao atualizar o grupo. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
    }
  },
  created () {
    this.fetchWppGroups()
  }
}
</script>
