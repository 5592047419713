<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" :width=700>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Configurar grupo do whatsapp
            </p>
          </header>
          <section class="modal-card-body">
            <b-field label="Nome do grupo">
              <b-input type="text" v-model="name" placeholder="Nome" required disabled>
              </b-input>
            </b-field>
            <b-field label="Responsável pelo grupo" expanded>
              <b-select
                expanded
                placeholder="Atendente"
                v-model="supervisor"
                :disabled="isDisabled"
              >
                <option
                  v-for="att in getAttendants"
                  :value="att.id"
                  :key="att.id"
                >
                  {{ att.name }}
                </option>
              </b-select>
            </b-field>
            <br>
            <div class="field">
              <b-checkbox v-model="addAll" :disabled="isSelectAllAttDisabled"
                >Adicionar todos os atendentes</b-checkbox
              >
            </div>
            <b-field label="Adicionar atendentes dos departamentos">
              <multiselect
                v-model="selectedDpts"
                :multiple="true"
                :options="departments"
                label="name"
                track-by="id"
                select-label=""
                deselect-label=""
                :disabled="isSelectByDptDisabled"
              >
                <template slot="placeholder"
                  >Departamento</template
                >
              </multiselect>
            </b-field>
            <b-field label="Atendentes do grupo">
              <multiselect
                v-model="selectedAttendants"
                :multiple="true"
                :options="getAttendants"
                label="name"
                track-by="id"
                :disabled="isSelectAttDisabled"
              >
                <template slot="placeholder">Atendentes</template>
              </multiselect>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button
              class="button is-primary"
              @click="updateGroup"
            >
              Atualizar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import multiselect from 'vue-multiselect'

export default {
  name: 'ModalUpdateWppGroup',
  components: {
    multiselect
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    attendants: {
      type: Array,
      required: true
    },
    groupUpdating: {
      type: Object,
      required: false
    },
    departments: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      name: '',
      selectedAttendants: [],
      selectedAttendant: '',
      supervisor: '',
      isDisabled: false,
      isSelectAttDisabled: false,
      isSelectAllAttDisabled: false,
      isSelectByDptDisabled: false,
      selectedDpts: [],
      addAll: false
    }
  },
  created () {
    if (this.groupUpdating) {
      this.name = this.groupUpdating.group_name
      this.supervisor = this.groupUpdating.attendant_id
      if (this.groupUpdating.attendant_id) {
        this.isDisabled = true
      }
    }
  },
  watch: {
    attendants (newAttendants, oldAttendants) {
      this.setSelectedAttendants(newAttendants)
    },
    selectedAttendants (newAttendants, oldAttendants) {
      if (this.supervisor && this.supervisor !== '' && !newAttendants.find(c => c.id === this.supervisor)) {
        this.setSelectedAttendants = oldAttendants
        this.$buefy.toast.open('Atenção, você não pode remover o responsável pelo ticket!')
      }
    },
    supervisor (newSupervisor, oldSupervisor) {
      if (oldSupervisor && oldSupervisor !== '') {
        this.selectedAttendants = this.selectedAttendants.filter(c => c.id !== oldSupervisor)
      }
      if (newSupervisor && newSupervisor !== '') {
        if (this.selectedAttendants.find(attendant => attendant.id === newSupervisor)) return
        this.selectedAttendants.unshift(this.attendants.find(attendant => attendant.id === newSupervisor))
      }
    },
    selectedDpts (newDpts, oldDpts) {
      this.disable()
      let atts = []
      if (newDpts.length > 0) {
        newDpts.forEach(dpt => {
          this.attendants.forEach(attendant => {
            if (attendant.departments && attendant.departments.find(d => d.id === dpt.id) && !atts.find(a => a.id === attendant.id)) {
              atts.push(attendant)
            }
          })
        })
      }
      if (this.supervisor && this.supervisor !== '' && !atts.find(c => c.id === this.supervisor)) {
        const sup = this.attendants.filter(c => c.id === this.supervisor)
        atts = [...sup, ...atts]
      }
      this.selectedAttendants = atts
    },
    addAll (newAdd, oldAdd) {
      this.disable()
      if (newAdd) {
        this.selectedAttendants = this.attendants
      } else if (!newAdd && oldAdd) {
        this.selectedAttendants = this.selectedAttendants.filter(att => att.id === this.supervisor)
      }
    }
  },
  computed: {
    getAttendants () {
      return this.attendants
    }
  },
  methods: {
    closeModal () {
      this.selectedAttendants = []
      this.$emit('closeModal')
    },
    selectAttendant () {
      if (this.selectedAttendants.find(attendant => attendant.id === this.selectedAttendant.id)) return
      this.selectedAttendants.unshift(this.attendants.find(attendant => attendant.id === this.selectedAttendant.id))
    },
    updateGroup () {
      this.emitUpdateGroup({
        id: this.groupUpdating.ticket_id,
        name: this.name,
        attendants: this.selectedAttendants,
        supervisor: this.supervisor
      })
    },
    setSelectedAttendants (attendants) {
      this.selectedAttendants = attendants.filter((attendant) => {
        return this.groupUpdating.attendants.some(selectedAttendant => {
          return attendant.id === selectedAttendant.id
        })
      })
    },
    removeAttendant (id) {
      if (id === this.groupUpdating.attendant_id) {
        this.$buefy.toast.open('Atenção, você não pode remover o responsável pelo ticket!')
      } else if (id === this.supervisor) {
        this.supervisor = ''
        this.selectedAttendants = this.selectedAttendants.filter(c => c.id !== id)
      } else {
        this.selectedAttendants = this.selectedAttendants.filter(c => c.id !== id)
      }
    },
    emitUpdateGroup (group) {
      this.$emit('updateGroup', group)
    },
    disable () {
      const all = this.addAll
      const dpt = this.selectedDpts.length
      if (all) {
        this.isSelectAttDisabled = true
        this.isSelectByDptDisabled = true
      }
      if (dpt > 0) {
        this.isSelectAttDisabled = true
        this.isSelectAllAttDisabled = true
      }
      if (!all & dpt === 0) {
        this.isSelectAttDisabled = false
        this.isSelectAllAttDisabled = false
        this.isSelectByDptDisabled = false
      }
    }
  }
}
</script>

<style scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
